import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Happy 1st  Birthday to Shanti Aid`}</h1>
    <p><em parentName="p">{`thank you for supporting 70 women and 117 children`}</em></p>
    <p>{` Congratulations on your achievement`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7c763e6c9525216f4d54ff4baab7e7df/eea4a/celebrate.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACAf/EABcBAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAVJ+8thbkU8cXUdH/8QAHBAAAgMAAwEAAAAAAAAAAAAAAQIAERIDECEx/9oACAEBAAEFAsAoVgHR++RrVS4vFx2yF5Bm7n//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/AUYKf//EABcRAAMBAAAAAAAAAAAAAAAAAAABEBL/2gAIAQIBAT8BZoc//8QAHRAAAgICAwEAAAAAAAAAAAAAABEBIRBBAhJRYf/aAAgBAQAGPwKtlTiqPpscwyvByoGjtHFY/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8hADAwE3CWuGXsX5DLWjQxGNF+3qG+XncVR26eRjoXxWZbYnly1Qd9iEBBMyz/2gAMAwEAAgADAAAAEMA6c//EABkRAQEAAwEAAAAAAAAAAAAAAAEAESExUf/aAAgBAwEBPxAEvy07I55f/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQARITH/2gAIAQIBAT8QbvGBdeMIm3//xAAgEAEAAgICAgMBAAAAAAAAAAABABEhQTFRYXGBkeHB/9oACAEBAAE/EHhUOxbv8g9qnQTXXUEOct2l/UqTFdvpDnmBNepMyfn9jCFlsHnz7ji/MiUjTLhWA5Ds9ZnLc5Z4PhjMpSKXcDEYWrXX9gQhM1pvHkn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "celebrate",
            "title": "celebrate",
            "src": "/static/7c763e6c9525216f4d54ff4baab7e7df/4b190/celebrate.jpg",
            "srcSet": ["/static/7c763e6c9525216f4d54ff4baab7e7df/e07e9/celebrate.jpg 200w", "/static/7c763e6c9525216f4d54ff4baab7e7df/066f9/celebrate.jpg 400w", "/static/7c763e6c9525216f4d54ff4baab7e7df/4b190/celebrate.jpg 800w", "/static/7c763e6c9525216f4d54ff4baab7e7df/e5166/celebrate.jpg 1200w", "/static/7c763e6c9525216f4d54ff4baab7e7df/eea4a/celebrate.jpg 1280w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      